/* eslint-disable camelcase */
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import CryptoJS from 'crypto-js'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import { NoSpace } from '@/libs/helpers'
import { loginAxiosIns, newAxiosIns } from '@/libs/axios'
import ModalResendVerification from './ModalResendVerification.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BForm,
    // BAlert,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    ModalResendVerification,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userId: '',
      error: '',
      status: '',
      usernameEmail: '',
      password: '',
      loading: false,
      // validation rules
      required,
      email,
      custommessages: {
        required: 'Mohon masukan password Kamu.',
      },
      custommessages1: {
        required: 'Mohon masukan username atau email.',
      },

      messageResendEmailVerification: '',

      // Mode Page
      isKompack: null,
      isKomcards: null,
      isKomship: null,
      userData: null,
      emailProfile: null,
      product: {
        allowed_login: false,
        partner_products: [],
      },
      loadingVerification: false,
      NoSpace,
      checkLoginCode: null,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.error = ''
          this.userId = ''

          const millis = Date.now()
          const hash = CryptoJS.MD5(`${millis}${process.env.VUE_APP_NOT_SECRET_KEY}`).toString()

          loginAxiosIns.post('/auth/api/v1/komship/login', {
            username_email: this.usernameEmail,
            password: this.password,
            login_from: 'website',
          }, {
            headers: {
              Authorization: `${millis}${hash}`,
            },
          })
            .then(response => {
              const {
                data: { data: { data, access_token } },
              } = response
              useJwt.setToken(access_token)
              this.getUser(data)
            })
            .catch(error => {
              this.loading = false
              if (error && error.response && error.response.data.meta.code === 400) {
                if (error.response.data.data && error.response.data.data.login_attempt > 0) {
                  this.$swal({
                    html: `<h3 class="font-bold text-2xl mt-[8px] text-[#333333]">Gagal Login</h3> <p class="my-[18px]">Tersisa ${error.response.data.data.login_attempt}x kesempatan untuk login. Apabila setelah 3x percobaan login tetap gagal, kamu dapat mencobanya lagi setelah 24 jam kedepan</p>`,
                    imageUrl: 'https://storage.googleapis.com/komerce/core/icon-popup-warning.png',
                    imageWidth: 104,
                    imageHeight: 100,
                    width: 600,
                    padding: '44px 24px',
                    confirmButtonText: 'Mengerti',
                    confirmButtonClass: 'btn btn-primary w-full',
                    customClass: {
                      actions: 'mt-0 px-0',
                    },
                  })
                } else {
                  this.$swal({
                    html: '<h3 class="font-semibold text-2xl mt-[8px] text-[#333333]">Gagal Login</h3> <p class="my-[18px]">Login Gagal 3x. Silakan coba lagi setelah 24 jam ke depan</p>',
                    imageUrl: 'https://storage.googleapis.com/komerce/core/icon-popup-warning.png',
                    confirmButtonText: 'Mengerti',
                    imageWidth: 104,
                    imageHeight: 100,
                    width: 600,
                    padding: '44px 24px',
                    confirmButtonClass: 'btn btn-primary w-full',
                    customClass: {
                      actions: 'mt-0 px-0',
                    },
                  })
                }
              } else if (error.response.data.meta.code === 401) {
                this.$toast_error({ message: `${error.response.data.meta.message}` })
              } else {
                this.$toast_error({ message: 'Gagal untuk login, silahkan coba lagi!' })
              }
            })
        }
      })
    },
    getUser(userData) {
      this.userId = userData.id
      this.$http
        .post('/user/get-profile', {
          user_id: this.userId,
        })
        .then(async response => {
          let ability = []

          let { data } = response.data
          data = Array.isArray(data) ? data[0] : data
          const role = data.role_name.toUpperCase()
          this.isKompack = data.is_kompack
          this.isKomcards = data.is_komcards
          this.isKomship = data.is_komship
          this.userData = data
          this.emailProfile = data.email

          switch (role) {
            case 'PARTNER':
              if (this.userData.is_komship === 1) {
                ability = [
                  { action: 'manage', subject: 'Komship TalentPool' },
                  { action: 'manage', subject: 'Komship Wishlist' },
                  { action: 'manage', subject: 'PartnerProfile' },
                  { action: 'read', subject: 'Dashboard Komship' },
                  { action: 'manage', subject: 'Customer' },
                  { action: 'manage', subject: 'Produk' },
                  { action: 'manage', subject: 'Tambah Produk' },
                  { action: 'manage', subject: 'Data Produk' },
                  { action: 'manage', subject: 'Order' },
                  { action: 'manage', subject: 'Tambah Order' },
                  { action: 'manage', subject: 'Data Order' },
                  { action: 'manage', subject: 'Pickup' },
                  { action: 'manage', subject: 'Ajukan Pickup' },
                  { action: 'manage', subject: 'History Pickup' },
                  { action: 'manage', subject: 'Keuangan' },
                  { action: 'manage', subject: 'Penghasilan' },
                  { action: 'manage', subject: 'Saldo' },
                  { action: 'manage', subject: 'Setting Komship' },
                  { action: 'manage', subject: 'Setting Profile' },
                  { action: 'manage', subject: 'Setting Access Account' },
                  { action: 'manage', subject: 'Setting Pickup Address' },
                  { action: 'manage', subject: 'Setting Rekening Bank' },
                  { action: 'manage', subject: 'Setting PIN' },
                  { action: 'manage', subject: 'Setting Ekspedisi' },
                  { action: 'manage', subject: 'Hiring' },
                  { action: 'manage', subject: 'Fitur Pendukung' },
                  { action: 'manage', subject: 'MyApps' },
                  { action: 'manage', subject: 'Kendala' },
                  { action: 'manage', subject: 'Inbox' },
                ]
                // KOMPACK
                if (this.userData.is_kompack === 1) {
                  ability.push({ action: 'manage', subject: 'Gudangku' })
                  ability.push({ action: 'manage', subject: 'Gudang' })
                  ability.push({ action: 'manage', subject: 'Cari Gudang' })
                  ability.push({ action: 'manage', subject: 'Ajukan Inbound' })
                  ability.push({
                    action: 'manage',
                    subject: 'Riwayat Pengajuan',
                  })
                }
                if (this.userData.is_kompack === 0) {
                  ability.push({ action: 'manage', subject: 'Gudangku' })
                  ability.push({ action: 'manage', subject: 'Gudang' })
                }
                if (this.userData.is_komcards === 1) {
                  ability.push({ action: 'manage', subject: 'KartuKomcards' })
                }
              } else {
                ability = [{ action: 'manage', subject: 'komtim' }]
              }
              if (this.userData.is_komcards === 1) {
                ability.push({ action: 'read', subject: 'Dashboard Komship' })
                ability.push({ action: 'manage', subject: 'Keuangan' })
                ability.push({ action: 'manage', subject: 'Setting Komship' })
                ability.push({ action: 'manage', subject: 'Setting Profile' })
                ability.push({ action: 'manage', subject: 'Setting Pickup Address' })
                ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                ability.push({ action: 'manage', subject: 'Setting PIN' })
                ability.push({ action: 'manage', subject: 'MyApps' })
                ability.push({ action: 'manage', subject: 'KartuKomcards' })
              }
              // KOMFORM
              if (this.userData.is_komform === 1) {
                ability.push({ action: 'read', subject: 'Dashboard Komship' })
                ability.push({ action: 'manage', subject: 'Setting Komship' })
                ability.push({ action: 'manage', subject: 'Setting Profile' })
                ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                ability.push({ action: 'manage', subject: 'Setting PIN' })
                ability.push({ action: 'manage', subject: 'Keuangan' })
                ability.push({ action: 'manage', subject: 'Saldo' })
                ability.push({ action: 'manage', subject: 'MyApps' })
                ability.push({ action: 'manage', subject: 'Order' })
                ability.push({ action: 'manage', subject: 'Form Order' })
                ability.push({ action: 'manage', subject: 'Inbox' })
              }
              // KOMCHAT
              if (this.userData.is_komchat === 1) {
                ability.push({ action: 'read', subject: 'Dashboard Komship' })
                ability.push({ action: 'manage', subject: 'Fitur Pendukung' })
                ability.push({ action: 'manage', subject: 'Customer' })
                ability.push({ action: 'manage', subject: 'Group Contact' })
              }
              break
            case 'SDM':
              ability = [
                { action: 'read', subject: 'TalentHome' },
                { action: 'manage', subject: 'TalentProfile' },
              ]
              break
            case 'KOMSHIP MEMBER':
              ability = [
                { action: 'manage', subject: 'Dashboard Komship' },
              ]
              break
            default:
              break
          }

          if (role === 'PARTNER') {
            data = await this.getPartnerProfile(this.userId)

            if (data.nik) {
              ability.push({ action: 'manage', subject: 'DetailTalent' })
              ability.push({ action: 'manage', subject: 'Wishlist' })
            }
          }

          if (role === 'TALENT GLOBAL') {
            const itemMember = await this.getAccessKomship(data.id)
            const menuAccess = itemMember.privilege.filter(item => item.access !== 'No Data Access.')
            menuAccess.forEach(item => {
              if (item.menu_name === 'PRODUCT') {
                ability.push({ action: 'manage', subject: 'Produk' })
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                const findAccessManage = item.access.find(accessItem => accessItem.access_id === 2)
                if (findAccessView !== undefined) ability.push({ action: 'manage', subject: 'Data Produk', path: '/produk' })
                if (findAccessManage !== undefined) ability.push({ action: 'manage', subject: 'Tambah Produk', path: '/add-produk' })
              }
              if (item.menu_name === 'ORDER') {
                ability.push({ action: 'manage', subject: 'Order' })
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                const findAccessManage = item.access.find(accessItem => accessItem.access_id === 2)
                if (findAccessView !== undefined) ability.push({ action: 'manage', subject: 'Data Order', path: '/data-order' })
                if (findAccessManage !== undefined) ability.push({ action: 'manage', subject: 'Tambah Order', path: '/add-order' })
              }
              if (item.menu_name === 'DASHBOARD') {
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                if (findAccessView !== undefined) ability.push({ action: 'read', subject: 'Dashboard Komship', path: '/' })
              }
              if (item.menu_name === 'GUDANG') {
                // const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                const findAccessCariGudang = item.access.find(accessItem => accessItem.access_id === 18)
                const findAccessGudangku = item.access.find(accessItem => accessItem.access_id === 16)
                const findAccessAjukanInbound = item.access.find(accessItem => accessItem.access_id === 17)
                const findAccessRiwayatPengajuan = item.access.find(accessItem => accessItem.access_id === 19)
                ability.push({ action: 'manage', subject: 'Gudang' })
                if (findAccessGudangku !== undefined) {
                  ability.push({ action: 'manage', subject: 'Gudangku', path: '/gudangku' })
                }
                if (findAccessCariGudang !== undefined) {
                  ability.push({ action: 'manage', subject: 'Cari Gudang', path: '/cari-gudang' })
                }
                if (findAccessAjukanInbound !== undefined) {
                  ability.push({ action: 'manage', subject: 'Ajukan Inbound', path: '/ajukan-inbound' })
                }
                if (findAccessRiwayatPengajuan !== undefined) {
                  ability.push({ action: 'manage', subject: 'Riwayat Pengajuan', path: '/riwayat-pengajuan' })
                }
              }
              if (item.menu_name === 'PICKUP') {
                ability.push({ action: 'manage', subject: 'Pickup' })
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 6)
                const findAccessManage = item.access.find(accessItem => accessItem.access_id === 5)
                if (findAccessView !== undefined) ability.push({ action: 'manage', subject: 'History Pickup', path: '/history-pickup' })
                if (findAccessManage !== undefined) ability.push({ action: 'manage', subject: 'Ajukan Pickup', path: '/ajukan-pickup' })
              }
              if (item.menu_name === 'FINANCE') {
                ability.push({ action: 'manage', subject: 'Keuangan' })
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 7)
                const findAccessManage = item.access.find(accessItem => accessItem.access_id === 8)
                if (findAccessView !== undefined) ability.push({ action: 'manage', subject: 'Penghasilan', path: '/keuangan/penghasilan' })
                if (findAccessManage !== undefined) ability.push({ action: 'manage', subject: 'Saldo', path: '/keuangan/saldo' })
              }
              if (item.menu_name === 'SETTING') {
                ability.push({ action: 'manage', subject: 'Setting Komship' })
                const findAccessBank = item.access.find(accessItem => accessItem.access_id === 12)
                const findAccessPin = item.access.find(accessItem => accessItem.access_id === 13)
                const findAccessExpedition = item.access.find(accessItem => accessItem.access_id === 14)
                if (findAccessBank !== undefined) ability.push({ action: 'manage', subject: 'Setting Rekening Bank', path: '/setting-kompship/rekening-bank' })
                if (findAccessPin !== undefined) ability.push({ action: 'manage', subject: 'Setting PIN', path: '/setting-kompship/pin' })
                if (findAccessExpedition !== undefined) ability.push({ action: 'manage', subject: 'Setting Ekspedisi', path: '/setting-kompship/ekspedisi' })
              }
              if (item.menu_name === 'KENDALA') {
                const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                if (findAccessView !== undefined) ability.push({ action: 'manage', subject: 'Kendala', path: '/ticketing' })
              }
            })
          }
          const path = await ability.find(pathItem => pathItem.path !== undefined)
          if (path !== undefined) Object.assign(data, { path: path.path })

          data.ability = ability
          this.$ability.update(ability)

          localStorage.setItem('userData', JSON.stringify(data))
          this.$store.commit('auth/UPDATE_USER_DATA', data)

          if (this.userData.is_komform === 1) {
            await this.loginKomform().then(() => {
              this.$router.go('/')
            })
          } else {
            this.$router
              .go(getHomeRouteForLoggedInUser(role, this.userData.is_komship))
              .then(() => {})
              .catch(error => {
                this.$refs.loginForm.setErrors(error.response.data.error)
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    getPartnerProfile(userId) {
      this.loading = true
      return this.$http
        .get(`/user/partner/get-profile/${userId}`)
        .then(response => response.data.data[0])
        .finally(() => {
          this.loading = false
        })
    },
    getAccessKomship(id) {
      const params = {
        user_id: id,
      }
      return this.$http.get('/user/partner/get-menu-komship-member', {
        params,
      }).then(response => response.data.data)
    },
    validationEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
      NoSpace(event)
    },
    loginKomform() {
      return new Promise((resolve, reject) => {
        window.addEventListener(
          'message',
          event => {
            if (event.data.type === 'komform-signin-complete') {
              resolve('OK')
            }
            if (event.data.type === 'komform-signin-error') {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('ERROR')
            }
          },
          false,
        )
        const iframe = document.createElement('iframe')
        iframe.src = `${process.env.VUE_APP_BASE_URL_KOMFORM}/auth/programmatic-signin?email=${this.usernameEmail}&password=${this.password}`
        iframe.width = '0'
        iframe.height = '0'
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
      })
    },
    async checkVerification(event) {
      this.loading = true
      const url = '/auth/api/v1/auth/check-login'
      const params = {
        email: event,
      }
      if (event) {
        await newAxiosIns
          .post(url, params)
          .then(res => {
            const {
              data: { partner_products, allowed_login },
            } = res.data
            let filteredProducts
            this.loading = false

            this.product.allowed_login = allowed_login
            this.checkLoginCode = null
            this.error = ''

            if (partner_products !== null) {
              filteredProducts = partner_products.filter(
                ({ is_verified }) => is_verified === false,
              )
            }

            if (partner_products !== null) {
              this.product.partner_products = filteredProducts
            }

            if (filteredProducts.length > 0) {
              this.error = 'Email Kamu belum diverifikasi'
            }
          })
          .catch(err => {
            const {
              response: {
                data: {
                  meta: { message, code },
                },
              },
            } = err
            this.checkLoginCode = code
            this.product.allowed_login = false
            this.loading = false

            if (message === 'user not registered') {
              this.error = 'Email kamu belum terdaftar'
            }
            if (message === 'user is non partner') {
              this.error = 'Maaf email kamu telah terdaftar di role lain pada Komerce. Kamu dapat menggunakan email lain untuk mendaftar'
            }
            this.$bvModal.hide('resend-verification')
          })
      } else {
        this.error = ''
      }
    },
    async handleModalVerification() {
      const productName = this.product.partner_products.find(
        ({ is_verified }) => is_verified === false,
      )

      if (this.product.partner_products.length > 1) {
        this.$bvModal.show('resend-verification')
      } else {
        const url = '/auth/api/v1/auth/resend-verification'
        const payload = {
          email: this.usernameEmail,
          product_name: productName.product_name,
        }
        this.loadingVerification = true
        await newAxiosIns
          .post(url, payload)
          .then(() => {
            this.$toast_success({ message: 'Verifikasi berhasil dikirim, silahkan cek email kamu!' })
            this.$bvModal.hide('resend-verification')
            this.loadingVerification = false
          })
          .catch(err => {
            const {
              response: {
                data: {
                  data: { error },
                },
              },
            } = err

            if (error === 'ERR_RETRYING_EMAIL_DENIED') {
              this.$toast_warning({ message: 'Mohon tunggu dalam waktu 90 detik untuk kirim ulang verifikasi' })
              this.loadingVerification = false
              return
            }

            this.$toast_error({ message: 'Verifikasi gagal dikirim!' })
            this.loadingVerification = false
          })
      }
    },
  },
}
