<template>
  <BModal
    id="resend-verification"
    ref="resend-verification"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <BRow class="justify-center flex flex-column p-2">
      <span class="text-2xl text-black text-center font-semibold">
        Kirim Ulang Verifikasi
      </span>
      <p class="text-black text-center my-1">
        Verifikasi semua produk untuk lebih memudahkan bisnis kamu, kamu juga bisa melakukannya di menu "<span class="font-semibold">Aplikasiku</span>".
      </p>
      <div
        v-for="(data, index) in product"
        :key="index"
        class="radio_hover border border-solid border-gray-300 rounded-lg p-[10px] mb-1"
      >
        <BFormRadio
          v-model="selectedItem"
          :value="data.product_name"
          class="d-inline-block mr-2"
        >
          <div class="flex items-center gap-2">
            <img
              :src="data.url_logo"
              alt="Komerce"
              width="25"
            >
            <span class="capitalize text-black font-medium">{{ data.product_name }}</span>
          </div>
        </BFormRadio>
      </div>
      <div class="flex flex-row justify-center gap-4">
        <BButton
          variant="outline-primary"
          @click="$bvModal.hide('resend-verification')"
        >
          Batal
        </BButton>
        <BButton
          :variant="loading || !selectedItem ? 'secondary' : 'primary'"
          :disabled="loading || !selectedItem"
          @click="onSubmit"
        >
          <BSpinner
            v-if="loading"
            variant="light"
            small
          />
          Kirim Ulang Verifikasi
        </BButton>
      </div>
    </BRow>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'

export default {
  props: {
    product: {
      type: Array,
      default: () => [],
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedItem: null,
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      const url = '/auth/api/v1/auth/resend-verification'
      const payload = {
        email: this.email,
        product_name: this.selectedItem,
      }
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.$toast_success({ message: 'Verifikasi berhasil dikirim, silahkan cek email kamu!' })
          this.$bvModal.hide('resend-verification')
          this.loading = false
        })
        .catch(err => {
          const {
            response: {
              data: {
                data: { error },
              },
            },
          } = err

          if (error === 'ERR_RETRYING_EMAIL_DENIED') {
            this.$toast_warning({ message: 'Mohon tunggu dalam waktu 90 detik untuk kirim ulang verifikasi' })
            this.loading = false
            return
          }

          this.$toast_error({ message: 'Verifikasi gagal dikirim!' })
          this.loading = false
        })
    },
  },
}
</script>
<style lang="css" scoped>
.radio_hover:hover {
  background-color: #FFECE9;
  border-color: #FFECE9 !important;
}
</style>
